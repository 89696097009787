.loading.cover-content {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loading.cover-page {
    position: fixed;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.loading .ant-spin {
    color: #3e79f7;
}

.ant-card.ant-card-bordered {
    border-radius: 0;
}

.ant-modal-content {
    border-radius: 0;
}

.ant-alert {
    border-radius: 0;
}

span.error {
    color: #ff0000;
}
.ant-select-selector {
    border-radius: 0 !important;
}
.ant-select-dropdown {
    border-radius: 0;
}
.ant-btn-warning {
    color: #fff;
    background: #f0ad4e;
    border-color: #f0ad4e;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}
.ant-btn-warning:focus, .ant-btn-warning:hover {
    color: #fff !important;
    background: #efbc74 !important;
    border-color: #efbc74 !important;
}
.ant-menu-item-selected {
    background-color: #efbc74 !important;
    border-right: 3px solid #FE801B !important;
    color: #000 !important;
}
.ant-menu-item:hover {
    color: #FE801B !important;
}
.ant-menu-inline .ant-menu-item::after {
    border-right: 3px solid #FE801B !important;
}
.ant-menu-submenu-selected{
    color: #FE801B !important;
}
.ant-menu-submenu:hover {
    color: #FE801B !important;
}
.ant-menu-submenu-title:hover {
    color: #FE801B !important; 
}
.ant-menu-submenu-arrow, .ant-menu-submenu-arrow::before, .ant-menu-submenu-arrow::after {
    color: rgb(69, 85, 96) !important; 
}
.greeting-card {
    display: flex;
    flex-direction: column;
    box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
    backdrop-filter: blur(14px);
    background-color: rgba(255, 255, 255, 0.2);
    margin: 0 10px;
    padding: 1rem;
    width: 100%;
    color: #000;
}
.statistic-card {
    display: flex;
    flex-direction: column;
    box-shadow: 0 15px 25px rgba(129, 124, 124, 0.2);
    background-color: #fff;
    margin-top: 1rem;
    padding: 1rem 1rem 0 1rem;
    border-bottom: 5px solid #FE801B !important;
    color: #000;
}
.custom-card {
    width: 100%;
    margin-top: 1rem !important;
    border-top: 5px solid #FE801B !important;
    margin-bottom: 0 !important;
}

.custom-card-quest {
    width: 100%;
    margin-top: 1rem !important;
    border-bottom: 10px solid #FE801B !important;
    margin-bottom: 0 !important;
}

.ant-btn {
    border-radius: 0 !important;
}
.ant-input {
    border-radius: 0 !important;
}
.ant-btn-round {
    border-radius: 40px !important;
}
.ant-btn-custom, .ant-btn-custom:hover {
    background-color: #FE801B !important;
    color: #FFF !important;
}
.custom-form-group {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: start;
    padding: 5px;
}
.ant-btn-custom label {
    color: #000;
    display: inline-block;
}
.custom-form-group input {
    background-color: #F1F1F1;
    border: none;
    height: 40px;
    border-radius: 40px !important;
    margin-top: 10px !important;
    padding: 10px !important;
}

.custom-form-group .ant-select .ant-select-selector {
    background-color: #F1F1F1;
    border-radius: 40px !important;
    margin-top: 10px !important;
}

.custom-form-group textarea {
    background-color: #F1F1F1;
    border-radius: 10px !important;
    margin-top: 10px !important; 
}

.ant-pagination-item-active {
    background-color: #FE801B;
}

.custom-modal {
    display: none;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0,0,0,.45);
    justify-content: center;
    align-items: center;
}

.custom-modal-card {
    background-color: #FFF;
    border-top: 5px solid #FE801B;
    padding: 1rem;
    max-height: 90vh;
    overflow: auto;
}

.custom-modal-header {
    display: flex;
    flex-direction: row;
    box-shadow: 0 5px 25px grey;
    background-color: #fff;
    justify-content: space-between;
    align-items: center;
    margin: -1rem -1rem 1rem -1rem;
    padding: 5px 1rem;
}

.modal-close-button {
    cursor: pointer;
    border: none;
    width: 25px;
    height: 25px;
    background-color: #FFF;
    background-size: 25px 25px;
    background-repeat: no-repeat;
    background-position: center;
}

.button-primary {
    background-color: #FE801B !important;
    color: #FFF !important;
    border-radius: 10px !important;  
    cursor: pointer; 
}

.button-error {
    background-color: #FF0000 !important;
    color: #FFF !important;
    border-radius: 10px !important; 
}

.button-info {
    background-color: #1877F2 !important;
    color: #FFF !important;
    border-radius: 10px !important;   
}

.custom-tabs .ant-tabs-nav {
    margin: 0 !important;
}

.custom-tabs {
    width: 100%;
    margin-top: 1rem !important;
}

.custom-tabs .custom-tabs-card {
    width: 100%;
    margin-top: -4rem !important;
    margin-bottom: 0 !important;
}

.custom-tabs .ant-tabs-nav {
    margin: 0;
}

.custom-tabs .ant-card-bordered {
    border-top: 5px solid #FE801B !important;
}

.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000 !important;
}

.custom-tabs .ant-tabs-tab {
    border: 2px solid #FE801B !important;
    margin: 0 !important;
}

.custom-tabs .ant-tabs-tab:hover {
    color: #000 !important;
}

.divider-primary {
    border: 1px solid #FE801B !important;
}

.custom-timeline {
    margin-top: 2rem;
}

.custom-timeline .not-complete {
    filter: opacity(0.5);
}

.custom-timeline .ant-timeline-item-head {
    background-color: #FE801B !important;
    border-color: #FE801B !important;
}

.custom-timeline .ant-timeline-item-tail {
    border-color: #FE801B !important;
}

.custom-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.custom-item-label {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
}

.custom-modal-button {
    display: flex;
    align-items: center;
    gap: 5px;
    height: 100%;
    font-size: 18px;
    font-weight: bold;
}

.loading{
    display: flex;
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    height: 100%;
    background-color: rgba(0,0,0,.45);
    justify-content: center;
    align-items: center;
}

.button-input-file {
    background-color: #FE801B;
    color: #fff;
    padding: 10px 20px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
}

.button-modal-info {
    background-color: #1877F2;
    color: #fff;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    font-size: medium;
}

.button-modal-error {
    background-color: #FF0000;
    color: #fff;
    padding: 5px 10px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    font-size: medium;
}


.badge-modal-info {
    background-color: #1877F2;
    color: #fff;
    padding: 2px 10px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    font-size: small;
}

.badge-modal-error {
    background-color: #FF0000;
    color: #fff;
    padding: 2px 10px;
    display: inline-block;
    border-radius: 10px;
    cursor: pointer;
    font-size: small;
}

.modal-title {
  display: flex;
  align-items: center;
  gap: 5px;  
}

.menu-logo {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
}

.view-button-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fff;
    background-color: #FE801B;
    padding: 10px 5px;
    text-align: center;
    margin: 10px 0;
    border-radius: 10px;
    cursor: pointer;
}

.custom-group {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
}

.indicator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    width: 100%;
    gap: 10px;
}

.line-indicator {
    width: 100%;
    border: 2px dashed #FE801B;
    position: absolute;
}

.step {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #ffb374;
    color: #fff;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.step.active {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: #FE801B;
    color: #fff;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.search-group {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: end;
    padding: 5px;
}

.quest-detail {
    font-size: 22px;
    color: #000;
    font-weight: bold;
}

.quest-logo {
    font-size: 16px;
    color: #000;
}

.quest-badge-info {
    width: 80px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7367FF;
    color: #fff;
    border-radius: 5px;
}

.quest-badge-error {
    width: 80px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FF0000;
    color: #fff;
    border-radius: 5px;
}

.button-modal-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #FE801B;
    width: 100px;
    height: 40px;
    border-radius: 10px !important;
}

.button-modal-primary:active, .button-modal-primary:hover, .button-modal-primary:focus {
    background-color: #FE801B !important;
    color: #fff !important;
}

.button-modal-error {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #FF0000;
    width: 100px;
    height: 40px;
    border-radius: 10px !important;
}

.button-modal-error:active, .button-modal-error:hover, .button-modal-error:focus {
    background-color: #FF0000 !important;
    color: #fff !important;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-input-disabled {
    color: rgb(69, 85, 96) !important;
}